<template>
<html lang="en" class="notranslate" translate="no"><!-- All translators -->
  <head>
    <meta name="google" content="notranslate" /> <!-- Just for google -->
  </head>
  <div class="app">
    <Menu :list="menuList"/>
    
    <div class="">
      <body> 
        <img class="mx-auto" src="@/assets/404.png" alt="not_found">
        <div class="flex flex-col items-center justify-center py-10 mb-24">
          <h1 class="mb-2 text-6xl font-bold text-center text-gray-800">Página No Encontrada</h1>
          <p class="mb-8 text-center text-gray-800 text-3xl pt-5">
            La página que esta buscando no existe o ha ocurrido un error.<br>
            Regresa a la página anterior o al inicio de <b>www.foncabsa.com</b> para elegir una nueva dirección.
          </p>
          <router-link
            to="/"
            class="btn-next px-9 py-3 text-4xl text-white hover:text-white bg-blue-100 rounded-md mt-5"
            >Ir a FONCABSA</router-link
          >
        </div>
      </body>
    </div>
  </div>
</html>
</template>
<script>
import Menu from '../components/Menu.vue';
import menuOptions  from "@/helpers/menu.js";
import { ref, onBeforeMount } from 'vue';

export default {
  name: "NotFound",
  setup() {
    let menuList = ref([]);

    onBeforeMount(async () => {
        await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
          menuList.value = resp.data.FONCABSA;
      }).catch(err => {
          console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
        menuList
    }
  },
  components:{
    Menu
  }
};
</script>
<style scoped>
.btn-next {
  background: rgb(130, 23, 64);
  text-decoration: none;
}
h1 {
  font-family: HelveticaNeueBold;
}
p {
  font-family: HelveticaNeueLight;
}
b {
    font-family: 'HelveticaNeueBold';
}
</style>